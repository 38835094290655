<template>
  <div>
    <v-layout wrap justify-center fill-height>
      <v-flex xs12 sm12 md12 lg2 hidden-md-and-down>
        <v-navigation-drawer
          class="fixedSidebar"
          v-model="sideNav"
          clipped
          width="100%"
          height="100vh"
          permanent
          color="#202020"
        >
          <v-layout wrap justify-center>
            <template v-for="(item, i) in navItems">
              <v-flex
                xs12
                :key="i"
                text-center
                py-2
                text-uppercase
                align-self-center
              >
                <v-layout wrap justify-center>
                  <v-flex xs12 v-if="item.name != 'Apiary'">
                    <router-link :to="item.route">
                      <span
                        :style="
                          $route.path == item.route
                            ? {
                                'font-family': 'poppinsbold',
                              }
                            : {
                                'font-family': 'poppinsthin',
                              }
                        "
                        style="
                          font-size: 15px;
                          color: #ffffff;
                          letter-spacing: 3px;
                          cursor: pointer;
                        "
                      >
                        {{ item.name }}
                      </span>
                    </router-link>
                  </v-flex>

                  <v-flex xs12 v-else>
                    <router-link
                      :to="'/Supervisor/Apiary/' + userData.assignedcluster._id"
                    >
                      <span
                        :style="
                          $route.path == '/Supervisor/Apiary'
                            ? {
                                'font-family': 'poppinsbold',
                              }
                            : {
                                'font-family': 'poppinsthin',
                              }
                        "
                        style="
                          font-size: 15px;
                          color: #ffffff;
                          letter-spacing: 3px;
                          cursor: pointer;
                        "
                      >
                        Apiary
                      </span>
                    </router-link>
                  </v-flex>
                  <v-flex xs12 pt-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </template>
          </v-layout>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md12 lg10 pt-12 pt-lg-0>
        <v-layout
          wrap
          justify-center
          :style="
            $route.name == 'Dashboard'
              ? 'background-color: #FFFFFF'
              : 'background-color: #fff9c4'
          "
        >
          <v-flex xs12 pa-4>
            <v-card
              min-height="100vh"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout wrap justify-center>
                <v-flex xs12 pa-4>
                  <router-view></router-view>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import store from "./../../store";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      sideNav: true,
      adminNavItems: [
        { name: "DASHBOARD", route: "/Admin" },
        { name: "APIARY LIST", route: "/Admin/Apiary" },
        { name: "HIVES", route: "/Admin/Hives" },
        { name: "EVENTS LIST", route: "/Admin/Events" },
        { name: "INVENTORY LIST", route: "/Admin/Inventory" },
        { name: "BEE KEEPERS", route: "/Admin/Users" },
        { name: "MAP", route: "/Admin/Map"},
        { name: "PROFILE", route: "/Profile" },
      ],
      supervisorNavItems: [
        { name: "User DASHBOARD", route: "/Supervisor/Dashboard" },
        { name: "APIARY",route: "/Supervisor/Apiary/",},
        { name: "HIVES", route: "/Supervisor/Hives" },
        { name: "EVENTS LIST", route: "/Supervisor/Events" },
        { name: "PROFILE", route: "/Profile" },
      ],
      userNavItems: [
        { name: "User DASHBOARD", route: "/Dashboard" },
        { name: "APIARY LIST", route: "/Apiary" },
        { name: "HIVES", route: "/Hives" },
        { name: "EVENTS LIST", route: "/Events" },
        { name: "INVENTORY", route: "/Inventory" },
        { name: "SUPERVISORS", route: "/Supervisors" },
        { name: "PROFILE", route: "/Profile" },
      ],
    };
  },
  computed: {
    navItems() {
      if (store.state.userType == "Admin") {
        return this.adminNavItems;
      } else if (store.state.userType == "Supervisor") {
        return this.supervisorNavItems;
      } else return this.userNavItems;
    },
    userData() {
      return store.state.userData;
    },
  },
  methods: {
    changeRoute(item) {
      if (this.$route.query.styl != item._id) {
        this.$router.push({
          path: "/Products",
          query: {
            styl: item._id,
            category: this.$route.query.category,
            subcategory: this.$route.query.subcategory,
            searchKey: this.$route.query.searchKey,
          },
        });
      } else {
        this.$router.push({
          path: "/Products",
          query: {
            styl: null,
            category: this.$route.query.category,
            subcategory: this.$route.query.subcategory,
            searchKey: this.$route.query.searchKey,
          },
        });
      }
    },
  },
};
</script>
<style>
@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    /* position: fixed; */

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
</style>